<template>
  <main class="AccountingView">
    <div class="container">
      <header class="mb-3">
        <h1>Accounting</h1>
      </header>

      <AppLoading v-if="loading" :spaced="false" />

      <AppEmptyList v-if="!loading && !Object.keys(report.year).length" />

      <div class="AccountingView__years" v-if="!loading && Object.keys(report.year).length">
        <div class="AccountingView__year mb-5" v-for="( yearData, year ) in report.year" :key="year">
          <div class="AccountingView__year__meta mb-3">
            <h2>{{ normalize(year) }}</h2>
            <p>${{ formatDecimal(yearData.total) }}</p>
          </div>

          <div class="AccountingView__months">
            <div class="AccountingView__month" v-for="( monthData, month ) in yearData.month" :key="month">
              <div class="card mb-2">
                <div class="card-body">
                  <div class="AccountingView__month__meta mb-3">
                    <h3>{{ formatDate('2000-' + normalize(month) + '-01', 'MMMM') }}</h3>
                    <p>${{ formatDecimal(monthData.total) }}</p>
                  </div>

                  <div class="AccountingView__invoices">
                    <div class="AccountingView__invoices__meta">
                      <button title="Toggle invoices" class="btn btn-link btn-sm" @click="toggleInvoices(year + month)" v-if="monthData.invoices.length">
                        <span v-if="!isInvoicesShown(year + month)">
                          <i class="bi bi-chevron-down" />
                          Show {{ formatNumber(monthData.invoices.length) }} invoices
                        </span>

                        <span v-if="isInvoicesShown(year + month)">
                          <i class="bi bi-chevron-up" />
                          Hide invoices
                        </span>
                      </button>

                      <router-link :to="{ name: 'accountingDetails', params: { year: normalize(year), month: normalize(month)} }">
                        Print all invoices
                      </router-link>
                    </div>

                    <div class="table-responsive mt-2" v-if="isInvoicesShown(year + month)">
                      <table class="AccountingView__invoices__table table">
                        <colgroup>
                          <col>
                          <col style="width: 150px">
                          <col style="width: 150px">
                          <col style="width: 150px">
                          <col style="width: 150px">
                          <col style="width: 50px">
                        </colgroup>
                        <thead>
                          <tr>
                            <th>Client</th>
                            <th>Invoice #</th>
                            <th class="text-end">Perf. fees</th>
                            <th class="text-end">Quarter. fees</th>
                            <th class="text-end">Due</th>
                            <th />
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="invoice in monthData.invoices" :key="invoice.id" @click.stop="go(invoice.id)">
                            <th class="text-primary">
                              {{ invoice.client.lastname }} {{ invoice.client.firstname }}
                            </th>
                            <td>{{ invoice.number }}</td>
                            <td class="text-end">
                              {{ formatDecimal(invoice.settings.performance_fee) }}%
                            </td>
                            <td class="text-end">
                              {{ formatDecimal(invoice.settings.quaterly_fee) }}%
                            </td>
                            <td class="text-end">
                              <strong class="text-primary">
                                ${{ formatDecimal(invoice.due.performance_fee + invoice.due.quaterly_fee) }}
                              </strong>
                            </td>
                            <td class="text-end">
                              <button title="Open invoice" class="btn btn-link" @click="go(invoice.id)">
                                <i class="bi bi-eye" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import AppLoading from '@/components/AppLoading'
  import AppEmptyList from '@/components/AppEmptyList'

  import invoicesService from '../services/invoices.service'

  import { formatDate } from '@/utils/date'
  import { formatDecimal, formatNumber } from '@/utils/number'
  import { mapState } from 'vuex'

  export default {
    components: {
      AppLoading,
      AppEmptyList
    },

    data() {
      return {
        loading: true,
        report: null,
        invoices: null,
        invoicesShown: {}
      }
    },

    computed: {
      ...mapState('auth', [
        'authenticated',
        'impersonated'
      ])
    },

    mounted() {
      this.load()
    },

    methods: {
      formatDate,
      formatDecimal,
      formatNumber,

      async load() {
        this.loading = true
        this.report = await invoicesService.fetchReport()
        this.invoices = await invoicesService.fetch()
        this.loading = false
      },

      go(id) {
        this.$router.push({ name: 'invoiceDetails', params: { id } })
      },

      toggleInvoices(index) {
        this.invoicesShown[index] = !this.invoicesShown[index]
      },

      isInvoicesShown(index) {
        return this.invoicesShown[index]
      },

      normalize(string) {
        return string.replace(/[YM]/, '')
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .AccountingView__total
    font-size: 2em
    font-weight: bold
    margin-bottom: $spacer
    color: $primary

  .AccountingView__invoices__table
    tr
      vertical-align: middle
      cursor: pointer

    th, td
      white-space: nowrap

  .AccountingView__year__meta,
  .AccountingView__month__meta,
  .AccountingView__invoices__meta
    display: flex
    align-items: center
    justify-content: space-between

    > *
      margin: 0

  .AccountingView__year__meta,
  .AccountingView__year__meta h2
    font-size: 22px
    font-weight: 500
    color: $secondary

    p
      color: $secondary

  .AccountingView__month__meta,
  .AccountingView__month__meta h3
    font-size: 16px
    font-weight: 500

    p
      color: $primary

  .AccountingView__invoices__meta .btn
    margin-left: -$spacer-2

    i
      margin-right: $spacer-1
</style>
